export const ProposalStatus = [
    { status: 'Not Started', bg:'#e36c33', id: '0'} ,
    { status: 'In Progress', bg:'cornflowerblue', id: '1'},
    { status: 'Passed', bg:'green', id: '2'},
    { status: 'Failed', bg:'red', id: '3'},
    // { status: 'Drawn', bg:'darkgray', id: '4'}
]

export const CheckBalance = {
    CreateProposalBalance: 100,
    ProposalVote: 1
}